import React, {useEffect, useState} from 'react'
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import Admin from "./components/Admin";
import Home from "./components/Home";
import Services from "./components/Services";
import Header from "./components/Header";
import Dashboard_CFO from "./components/Dashboard_CFO";
import Dashboard_Marketing from "./components/Dashboard_Marketing";
import Dashboard_Customer from "./components/Dashboard_Customer";

import GraphQL from "./components/GraphQL";
import MoviesFunc from "./components/MoviesFunc";
import OneMovieFunc from "./components/OneMovieFunc";
import OneGenreFunc from "./components/OneGenreFunc";
import EditMovieFunc from "./components/EditMovieFunc";
import LoginFunc from "./components/LoginFunc";

import {ChakraProvider,} from '@chakra-ui/react';


export default function AppFunc(props) {
    const [jwt, setJWT] = useState("");

    useEffect(() => {
        let t = window.localStorage.getItem("jwt");
        if (t) {
            if (jwt === "") {
                setJWT(JSON.parse(t));
            }
        }
    }, [jwt])

    function handleJWTChange(jwt) {
        setJWT(jwt);
    }

    function logout() {
        setJWT("");
        window.localStorage.removeItem("jwt");
    }

    function login() {

    }


    return (
        <ChakraProvider>
            <Router>
                <div className="container">
                    <Header jwt={jwt} logout={logout}/>

                    <div className="row">
                        <div className="mt-5"></div>
                    </div>
                    <div className="row">
                        <div className="mt-5"></div>
                    </div>

                    <div className="row">

                        <div className="col-md-10">
                            <Switch>
                                <Route path="/movies/:id" component={OneMovieFunc}/>

                                <Route path="/movies">
                                    <MoviesFunc/>
                                </Route>

                                <Route path="/genre/:id" component={OneGenreFunc}/>

                                <Route
                                    exact
                                    path="/login"
                                    component={(props) => (
                                        <LoginFunc {...props} handleJWTChange={handleJWTChange}/>
                                    )}
                                />

                                <Route exact path="/services" component={Services}/>
                                <Route exact path="/dashboard_cfo" component={Dashboard_CFO}/>
                                <Route exact path="/dashboard_marketing" component={Dashboard_Marketing}/>
                                <Route exact path="/dashboard_customer" component={Dashboard_Customer}/>

                                <Route exact path="/graphql">
                                    <GraphQL/>
                                </Route>

                                <Route
                                    path="/admin/movie/:id"
                                    component={(props) => (
                                        <EditMovieFunc {...props} jwt={jwt}/>
                                    )}
                                />

                                <Route
                                    path="/admin"
                                    component={(props) => (
                                        <Admin {...props} jwt={jwt}/>
                                    )}
                                />

                                <Route exact path="/" component={Home}/>

                            </Switch>
                        </div>
                    </div>
                </div>

            </Router>
        </ChakraProvider>
    );
}