import React, { Component } from 'react';
import {DashStatistics} from './DashStatistics';
//import Header from '../components/Header';
//import Footer from '../components/Footer';
import { ChakraProvider } from "@chakra-ui/react";

            
export default class DashboardHome extends Component {
  render() {
    return (
      <div className="home">
{/*          <Header></Header>*/}
          <section>
            <div>
            <ChakraProvider>
            <DashStatistics />
            </ChakraProvider> 
            </div>
      </section>
        {/*<Footer></Footer>*/}
      </div>
    )
  }
}
