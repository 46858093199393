import React from 'react';
//import Header from '../components/Header';
//import Footer from '../components/Footer';
import Ticket from "./../images/logo.png";
import CustDash from "./../images/snapshot_customer_dashboard.png";
import CustRpt from "./../images/Seedz_customer_report.png";
import "./Home.css";
import {Box, Center, Heading, Text, chakra, Flex, LinkBox, LinkOverlay, SimpleGrid, Stack} from "@chakra-ui/react"
import { CheckCircleIcon } from '@chakra-ui/icons';


const Services = () => {
    return (

        <div className="services">

            <Center>
            {/*<h3>test</h3>*/}


                <Stack direction='column'>
                <img src={CustDash} alt="customer dashboard"/>
                    <img src={CustRpt} alt="customer report"/>
                </Stack>
            </Center>
        </div>
    )
}

export default Services