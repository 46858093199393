import {
  Box,
  chakra,
  Flex,
  AddIcon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Tab,
  Tabs,
  TabPanels,
  TabList,
  TabPanel,
  CircularProgress,
  CircularProgressLabel,
  Center,
  Divider,
  StackDivider,
  Heading,
  Spacer,
  Button,
  HStack,
  VStack,
  Container,
  ChakraProvider,
} from '@chakra-ui/react';
import MapChart from "./MapChart";
import Feature2Grid from "./Feature2Grid";
import { VictoryChart, VictoryGroup, VictoryBar} from "victory";
import { ReactNode} from 'react';
import { BsCloudDownload, BsPerson } from 'react-icons/bs';
import { IconName } from "react-icons/md";
import { AiOutlineWallet, AiOutlineSwap } from 'react-icons/ai';
import { FaBitcoin, FaEthereum, FaDollarSign } from 'react-icons/fa';
import { MdCall } from 'react-icons/md';
import PropTypes from 'prop-types'
import transactionsreport from '../images/logo.png'
import VictoryFirst from './VictoryFirst'
//import VictoryAreaA from './VictoryAreaA'
//import NewsCard from './NewsCard';

//interface StatsCardProps {
//  title: string;
//  stat: string;
//  icon: ReactNode;
//}

 //  function StatsCard(props: StatsCardProps) {
export function StatsCard({ title, stat, stat_sub, icon, color , textcolor='black'}) {
//  const { title, stat, icon } = props;
      return (
    
       <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor='gray.800'
      rounded={'lg'}
      opacity={'100%'}
      bg={color}>
        
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }} >
          <StatLabel color={textcolor} fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber color={textcolor} fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
          <StatHelpText color={textcolor} >
        <StatArrow type='increase' />
          {stat_sub}
        </StatHelpText>
        </Box>
        <Box
          my={'auto'}
          color={textcolor}
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  
  );
}

StatsCard.propTypes = {
    title: PropTypes.string,
    stat: PropTypes.string,
    stat_sub: PropTypes.string,
    icon: PropTypes.elementType,
    color: PropTypes.string
   }

export const StatsReport=()=> {
    //  const { title, stat, icon } = props;
      return (
//     <Flex height='80px'>
        <>
               <Flex height='80px'>
          <Box p='2'>
          <Heading size='md'>Your Active Campaign Report</Heading>
          </Box>
                  <Spacer />
                  <Box>
                    <Button href={transactionsreport} leftIcon={<BsCloudDownload />} colorScheme='orange' variant='solid' mr='4'>
                      Download
                    </Button>
                    <Button rightIcon={<MdCall />} colorScheme='blue' variant='outline'>
                    Call us
                    </Button>
                  </Box>
               </Flex>
          <Table variant='striped' colorScheme='grey'>
            <TableCaption>Campaign Results</TableCaption>
            <Thead>
              <Tr>
                <Th>Seed Currency</Th>
                <Th>Seed Currency Name</Th>
                <Th>Number of Customers</Th>
                <Th>Collected Seeds</Th>
                <Th>Collection Evolution</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                <Td>Bitcoin</Td>
                <Td>21,201</Td>
                <Td>$182,260,050</Td>
                <Td>
                  <CircularProgress value={80} color='green.400'>
                    <CircularProgressLabel>+80%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
              <Tr>
                <Td><FaEthereum size={'3em'} color='blue' /></Td>
                <Td>Ethereum</Td>
                <Td>$12,001</Td>
                <Td>$242,021,110</Td>
                <Td>
                  <CircularProgress value={75} color='green.400'>
                    <CircularProgressLabel>+75%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
              <Tr>
                <Td><FaDollarSign size={'3em'} color='grey' /></Td>
                <Td>Solana</Td>
                <Td>$1,010</Td>
                <Td>$8,212,562</Td>
                <Td>
                  <CircularProgress value={18} color='red.400'>
                    <CircularProgressLabel>-18%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Seed Currency</Th>
                <Th>Seed Currency Name</Th>
                <Th>Number of Customers</Th>
                <Th>Collected Seeds</Th>
                <Th>Collection Evolution</Th>
              </Tr>
            </Tfoot>
          </Table></>
    
          
      );
    }

    export const StatsAudience=()=> {
      //  const { title, stat, icon } = props;
        return (
        
        <><Flex bg='gray.100' p='4' fontSize='4xl'>
            Traffic Boost Analysis
          </Flex>
          <Center w='100%'>
              <SimpleGrid columns={{ base: 3, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                <Box p='4' fontSize='3xl' alignContent={'center'}>
                  Before
                </Box>
                <Box p='4' fontSize='3xl' alignContent={'center'}>
                  After
                </Box>
                <Box p='4' fontSize='3xl' alignContent={'center'}>
                </Box>
                <StatsCard
                  title={'Total Visits'}
                  stat={'2,231'}
                  //icon={<AiOutlineWallet size={'3em'} />}
                  color={'#eeeee4'} />
                <StatsCard
                  title={'Total Visits'}
                  stat={'12,231'}
                  stat_sub={'+ 15% since last month'}
                  //icon={<AiOutlineWallet size={'3em'} />}
                  textcolor={'white'}
                  color={'#071ADA'} />
                <VictoryChart>
            <VictoryGroup offset={20}
              colorScale={"qualitative"}
            >
            <VictoryBar
              data={[{ x: 'Jan', y: 1 }, { x: 'Feb', y: 2 }, { x: 'Mar', y: 5 }]}
            />
            <VictoryBar
              data={[{ x: 'Jan', y: 2 }, { x: 'Feb', y: 1 }, { x: 'Mar', y: 7 }]}
            />
            <VictoryBar
              data={[{ x: 'Jan', y: 3 }, { x: 'Feb', y: 4 }, { x: 'Mar', y: 9 }]}
            />
            </VictoryGroup>
            </VictoryChart>
                <StatsCard
                  title={'Bounce Rate'}
                  stat={'46%'}
                  stat_sub={'+ 12%'} // '\u20AC'+ ' for euro //
                  color={'#eeeee4'} />
                <StatsCard
                  title={'Bounce Rate'}
                  stat={'26%'}
                  stat_sub={'+ 12%'} // '\u20AC'+ ' for euro //
                  color={'#071ADA'}
                  textcolor={'white'} />
                <VictoryChart>
            <VictoryGroup offset={20}
              colorScale={"qualitative"}
            >
            <VictoryBar
              data={[{ x: 'Jan', y: 1 }, { x: 'Feb', y: 2 }, { x: 'Mar', y: 5 }]}
            />
            <VictoryBar
              data={[{ x: 'Jan', y: 2 }, { x: 'Feb', y: 1 }, { x: 'Mar', y: 7 }]}
            />
            <VictoryBar
              data={[{ x: 'Jan', y: 3 }, { x: 'Feb', y: 4 }, { x: 'Mar', y: 9 }]}
            />
            </VictoryGroup>
            </VictoryChart>
              <StatsCard
                  title={'Average Time Spent'}
                  stat={'3.2 sec.'}
                  stat_sub={'+ 16%'}
                  icon={<BsPerson size={'3em'} />}
                  color={'#eeeee4'} />
                <StatsCard
                  title={'Average Time Spent'}
                  stat={'1,020 sec.'}
                  stat_sub={'+ 16%'}
                  icon={<BsPerson size={'3em'} />}
                  color={'#071ADA'}
                  textcolor={'white'} />
              <VictoryChart>
            <VictoryGroup offset={20}
              colorScale={"qualitative"}
            >
            <VictoryBar
              data={[{ x: 'Jan', y: 1 }, { x: 'Feb', y: 2 }, { x: 'Mar', y: 5 }]}
            />
            <VictoryBar
              data={[{ x: 'Jan', y: 2 }, { x: 'Feb', y: 1 }, { x: 'Mar', y: 7 }]}
            />
            <VictoryBar
              data={[{ x: 'Jan', y: 3 }, { x: 'Feb', y: 4 }, { x: 'Mar', y: 9 }]}
            />
            </VictoryGroup>
            </VictoryChart>
            </SimpleGrid>
            </Center>
            
            <Flex bg='white' p='2' fontSize='4xl'>
            
            </Flex>
            <Flex bg='gray.100' p='4' fontSize='4xl'>
            Geography
            </Flex>
            <HStack 
                  divider={<StackDivider borderColor='gray.200' />}
                  spacing={4}
                  align='stretch'>
            <Box p='12'>
            <VStack divider={<StackDivider borderColor='gray.200' />}
                  spacing={4}
                  align='stretch'
                  p='10'
                  >
              <StatsCard
                  title={'1st Traffic Source'}
                  stat={'Paris 34%'}
                  icon={<BsPerson size={'3em'} />}
                  color={'#eeeee4'} />
                  <StatsCard
                  title={'2nd Traffic Source'}
                  stat={'Amsterdam 15%'}
                  icon={<BsPerson size={'3em'} />}
                  color={'#eeeee4'} />
              </VStack>
              </Box>
              <Box w='600px' p='10'> 
              <MapChart/>
              </Box>
              </HStack>
            </>
        
            
        );
      };


export const DashMonitor =()=> {
  return (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Feature2Grid/>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={'Onboarded Clients'}
          stat={'72,231'}
          stat_sub={'+ 3% since last month'}
          icon='👍'
          //icon={<AiOutlineWallet size={'3em'} />}
          color={'#edc7b7'}
        />
        <StatsCard
          title={'Active Clients'}
          stat={'50,502'}
          stat_sub={'+ 12%'} // '\u20AC'+ ' for euro //
          icon={<AiOutlineSwap size={'3em'} />}
          color={'#eee2dc'}
        />
        <StatsCard
          title={'Loyalty account visits'}
          stat={'11,120'}
          stat_sub={'+ 16%'}
          icon={<BsPerson size={'3em'} />}
          color={'#bab2b5'}
        />
      </SimpleGrid>
      <Center height='40px'>
          <Divider orientation='vertical' />
      </Center>
  
  <Tabs variant='soft-rounded' colorScheme='green'>
  <TabList>
    <Tab>Audience Analysis</Tab>
    <Tab>Active Campaigns</Tab>
    <Tab>DeepAI Analytics</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
      <p><StatsAudience/></p>
    </TabPanel>
    <TabPanel>
      <p><StatsReport/></p>
    </TabPanel>
    <TabPanel>
      
      <HStack>
        {/* <Container maxW='container.sm'><VictoryAreaA/></Container> */}
        <Container maxW='container.sm'><VictoryFirst/></Container>
        <Container maxW='container.sm'><VictoryFirst/></Container>
      </HStack>
     {/* <NewsCard/>*/ }
    </TabPanel>
  </TabPanels>
</Tabs>

  </Box>

  );
};