import React, { useEffect, useState  } from "react";
import { VictoryChart, VictoryScatter} from "victory";

class CatPoint extends React.Component {
  render() {
    const {x, y, datum} = this.props; // VictoryScatter supplies x, y and datum
    const cat = datum._y >= 0 ? "😻" : "😹";
    //const cat = ((datum._y >= 0)&&(datum._y < 0.1)) ? "😻" : "😹";
    return (
      <text x={x} y={y} fontSize={30}>
        {cat}
      </text>
    );
  }
}
//const wantedGraphicData = [{ y: 10 }, { y: 50 }, { y: 40 }]; // Data that we want to display
//const defaultGraphicData = [{ y: 0 }, { y: 0 }, { y: 100 }]; // Data used to make the animate prop work

export default function VictoryCat() {
    
//    const [graphicData, setGraphicData] = useState(defaultGraphicData);
    
//      useEffect(() => {
 //       setGraphicData(wantedGraphicData); // Setting the data that we want to display
 //     }, []);
 return (
    <VictoryChart
    animate={{ easing: 'exp' }}>
        <VictoryScatter
          dataComponent={<CatPoint/>}
          y={(d) => Math.sin(2 * Math.PI * d.x)}
          samples={15}
          />
      </VictoryChart>
  );
}