import React, {Fragment} from "react";
import { BrowserRouter as Router, Switch, Route, Link as ReachLink } from "react-router-dom";
import {
    Box,
    Stack,
    Heading,
    Flex,
    Text,
    Link,
    Button,
    useDisclosure
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";


const Header = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleToggle = () => (isOpen ? onClose() : onOpen());

    let loginLink;
    if (props.jwt === "") {
        //loginLink = <Link as={ReachLink} to="/login">Login</Link>;
        loginLink = (<Link as={ReachLink} href="/login" passhref="true">
            <Button as="a"
                    variant="outline"
                    _hover={{ bg: "gray.700", borderColor: "gray.700" }}
            >Login</Button>
        </Link>);
    } else {
        loginLink = (
/*            <Link to="/logout" onClick={props.logout}>
                Logout
            </Link>*/
            <Link as={ReachLink} href="/logout" passhref="true">
                <Button as="a"
                        variant="outline"
                        _hover={{ bg: "gray.700", borderColor: "gray.700" }}
                        onClick={props.logout}>Logout</Button>
            </Link>
        );
    }

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding={4}
            bg="teal.500"
            boxSize="full"
            color="white"
            {...props}
        >
            <Flex align="center" mr={100}>
                <Heading as="h1" size="lg" letterSpacing={"tighter"}>
                    Tickeo
                </Heading>
            </Flex>

            <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
                <HamburgerIcon />
            </Box>

            <Stack
                direction={{ base: "column", md: "row" }}
                display={{ base: isOpen ? "block" : "none", md: "flex" }}
                width={{ base: "full", md: "auto" }}
                alignItems="center"
                flexGrow={1}
                mt={{ base: 4, md: 0 }}
            >
                <div>{loginLink}</div>
                <Link as={ReachLink} to="/">Home</Link>
                <Link as={ReachLink} to="/login">Log</Link>
                <Link as={ReachLink} to="/movies">Data</Link>



                {props.jwt !== "" && (
                    <Fragment>
                        <Link as={ReachLink} to="/services">SERVICES</Link>
                        <Link as={ReachLink} to="/dashboard_cfo">CFO</Link>
                        <Link as={ReachLink} to="/dashboard_marketing">MARKETER</Link>
                        <Link as={ReachLink} to="/dashboard_customer">USER</Link>
                    </Fragment>
                )}
{/*                <Text>Docs</Text>
                <Text>Examples</Text>
                <Text>Blog</Text>*/}
            </Stack>

            <Box
                display={{ base: isOpen ? "block" : "none", md: "block" }}
                mt={{ base: 4, md: 0 }}
            >
                <Button
                    variant="outline"
                    _hover={{ bg: "teal.700", borderColor: "teal.700" }}
                >
                    Join Waitlist
                </Button>
            </Box>
        </Flex>
    );
};

export default Header;