import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Tab,
  Tabs,
  TabPanels,
  TabList,
  TabPanel,
  CircularProgress,
  CircularProgressLabel,
  Center,
  Divider,
  Heading,
  Spacer,
  Button,
  HStack,
  VStack,
  Container,
  Avatar,
  Text,
  Badge,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BsCloudDownload, BsPerson } from 'react-icons/bs';
import { AiOutlineWallet, AiOutlineSwap } from 'react-icons/ai';
import { FaBitcoin, FaEthereum, FaDollarSign } from 'react-icons/fa';
import { MdCall } from 'react-icons/md';
import PropTypes from 'prop-types'
import transactionsreport from '../images/logo.png'
import VictoryFirst from './VictoryFirst'
import VictoryCat from './VictoryCat'

//interface StatsCardProps {
//  title: string;
//  stat: string;
//  icon: ReactNode;
//}

 //  function StatsCard(props: StatsCardProps) {
export function StatsCard({ title, stat, stat_sub, icon, color }) {
//  const { title, stat, icon } = props;
  return (
    
       <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor='gray.800'
      rounded={'lg'}
      opacity={'70%'}
      bgGradient={color}>
        
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }} >
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
          <StatHelpText>
        <StatArrow type='increase' />
          {stat_sub}
        </StatHelpText>
        </Box>
        <Box
          my={'auto'}
          color='gray.800'
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  
  );
}

StatsCard.propTypes = {
    title: PropTypes.string,
    stat: PropTypes.string,
    stat_sub: PropTypes.string,
    icon: PropTypes.elementType,
    color: PropTypes.string
   }

export const StatsReport=()=> {
    //  const { title, stat, icon } = props;
      return (
//     <Flex height='80px'>
        <>
               <Flex height='80px'>
          <Box p='2'>
          <Heading size='md'>Your Active Seeding Report</Heading>
          </Box>
                  <Spacer />
                  <Box>
                    <Button href={transactionsreport} leftIcon={<BsCloudDownload />} colorScheme='orange' variant='solid' mr='4'>
                      Download
                    </Button>
                    <Button rightIcon={<MdCall />} colorScheme='blue' variant='outline'>
                    Call us
                    </Button>
                  </Box>
               </Flex>
          <Table variant='striped' colorScheme='grey'>
            <TableCaption>Seed Results</TableCaption>
            <Thead>
              <Tr>
                <Th>Seed Currency</Th>
                <Th>Seed Currency Name</Th>
                <Th>Number of Customers</Th>
                <Th>Collected Seeds</Th>
                <Th>Collection Evolution</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                <Td>Bitcoin</Td>
                <Td>21,201</Td>
                <Td>$182,260,050</Td>
                <Td>
                  <CircularProgress value={80} color='green.400'>
                    <CircularProgressLabel>+80%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
              <Tr>
                <Td><FaEthereum size={'3em'} color='blue' /></Td>
                <Td>Ethereum</Td>
                <Td>$12,001</Td>
                <Td>$242,021,110</Td>
                <Td>
                  <CircularProgress value={75} color='green.400'>
                    <CircularProgressLabel>+75%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
              <Tr>
                <Td><FaDollarSign size={'3em'} color='grey' /></Td>
                <Td>Solana</Td>
                <Td>$1,010</Td>
                <Td>$8,212,562</Td>
                <Td>
                  <CircularProgress value={18} color='red.400'>
                    <CircularProgressLabel>-18%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Seed Currency</Th>
                <Th>Seed Currency Name</Th>
                <Th>Number of Customers</Th>
                <Th>Collected Seeds</Th>
                <Th>Collection Evolution</Th>
              </Tr>
            </Tfoot>
          </Table></>
    
          
      );
    }

    export const StatsTransaction=()=> {
      //  const { title, stat, icon } = props;
        return (
          <><Flex height='80px'>
          <Box p='2'>
            <Heading size='md'>Weather check</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button href={transactionsreport} leftIcon={<BsCloudDownload />} colorScheme='orange' variant='solid' mr='4'>
              Download
            </Button>
            <Button rightIcon={<MdCall />} colorScheme='blue' variant='outline'>
            Call us
            </Button>
          </Box>
        </Flex>

 <Table variant='striped' colorScheme='grey'>
              <TableCaption>Weather Check</TableCaption>
              <Thead>
                <Tr>
                  <Th>Product Type</Th>
                  <Th>Transaction Type</Th>
                  <Th>Notional</Th>
                  <Th>Currency</Th>
                  <Th>Currency Name</Th>
                  <Th>Exec Date</Th>
                  <Th>Settlement Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Hedge</Td>
                  <Td>Increase</Td>
                  <Td>$210,012</Td>
                  <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                  <Td>Bitcoin</Td>
                  <Td>20220-06-20</Td>
                  <Td>2022-06-22</Td>
                </Tr>
                <Tr>
                  <Td>Hedge</Td>
                  <Td>Increase</Td>
                  <Td>$103,201</Td>
                  <Td><FaEthereum size={'3em'} color='blue' /></Td>
                  <Td>Ethereum</Td>
                  <Td>20220-06-19</Td>
                  <Td>2022-06-21</Td>
                </Tr>
                <Tr>
                  <Td>Borrow</Td>
                  <Td>Increase</Td>
                  <Td>$303,201</Td>
                  <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                  <Td>Bitcoin</Td>
                  <Td>20220-06-18</Td>
                  <Td>2022-06-20</Td>
                </Tr>
                <Tr>
                  <Td>Hedge</Td>
                  <Td>Increase</Td>
                  <Td>$10,501</Td>
                  <Td><FaDollarSign size={'3em'} color='grey' /></Td>
                  <Td>Solana</Td>
                  <Td>20220-06-18</Td>
                  <Td>2022-06-20</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>Product Type</Th>
                  <Th>Transaction Type</Th>
                  <Th>Notional</Th>
                  <Th>Currency</Th>
                  <Th>Currency Name</Th>
                  <Th>Exec Date</Th>
                  <Th>Settlement Date</Th>
                </Tr>
              </Tfoot>
            </Table></>
      
            
        );
      }


export const DashExchange =()=> {
  return (
    <><></><Flex>
          <VStack>
          <Center height='40px'>
                  <Divider orientation='vertical' />
          </Center>
          <Avatar src='https://bit.ly/sage-adebayo' />
          <Box ml='3'>
              <Text fontWeight='bold'>
                  Segun Adebayo
                  <Badge ml='1' colorScheme='green'>
                      New 
                  </Badge>
              </Text>
              <Text fontSize='sm'>Member since March 2012</Text>
          </Box>
          </VStack>
      </Flex><Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
              <chakra.h1
                  textAlign={'center'}
                  fontSize={'4xl'}
                  py={10}
                  fontWeight={'bold'}>
                  A little bit of game, a lot of appreciation.
              </chakra.h1>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
                  <StatsCard
                      title={'Seeds'}
                      stat={'72,231'}
                      stat_sub={'+ 3% since last month'}
                      icon={<AiOutlineWallet size={'3em'} />}
                      color="linear(to-l, #fec180,#ff8993)" />
                  <StatsCard
                      title={'Miles not seeded'}
                      stat={'50,502'}
                      stat_sub={'+ 12%'} // '\u20AC'+ ' for euro //
                      icon={<AiOutlineSwap size={'3em'} />}
                      color="linear(to-r, #d0ffae, #34ebe9)" />
                  <StatsCard
                      title={'Seeder Ranking'}
                      stat={'11,120 out of 13,560'}
                      stat_sub={'You are up 123 ! Congratulation'}
                      icon={<BsPerson size={'3em'} />}
                      color="linear(to-r, #f3dcfb, #679fe4)" />
              </SimpleGrid>
              <Center height='40px'>
                  <Divider orientation='vertical' />
              </Center>
              <Tabs variant='soft-rounded' colorScheme='green'>
                  <TabList>
                      <Tab>Watch your Seedz</Tab>
                      <Tab>Weather your Seedz</Tab>
                      <Tab>Harvest your Seedz</Tab>
                  </TabList>
                  <TabPanels>
                      <TabPanel>
                          <p><StatsReport /></p>
                      </TabPanel>
                      <TabPanel>
                          <p> two {/* <WeatherSeedz/> */}</p>
                          <p>  <Container maxW='container.sm'>
                               'toto'
                              </Container></p>
                      </TabPanel>
                      <TabPanel>

                          <HStack>
                              <Container maxW='container.sm'><VictoryCat /></Container>
                              <Container maxW='container.sm'><VictoryFirst /></Container>
                              <Container maxW='container.sm'><VictoryFirst /></Container>
                          </HStack>
                      </TabPanel>
                  </TabPanels>
              </Tabs>

          </Box></>

  );
};