import React from 'react';
//import Header from '../components/Header';
//import Footer from '../components/Footer';
import Ticket from "./../images/logo.png";
import "./Home.css";
import {Box, Center, Heading, Text, chakra, Flex, LinkBox, LinkOverlay, SimpleGrid, Stack} from "@chakra-ui/react"
import { CheckCircleIcon } from '@chakra-ui/icons';

const colors = {
    brand: {
        900: "#1a365d",
        800: "#153e75",
        700: "#2a69ac",
        600: "#3c2175"
    },
}

const Home = () => {
    return (
        <div className="home">
            {/*<h3>test</h3>*/}
            <Box textAlign="center" py={10} px={6}>
                <CheckCircleIcon boxSize={'50px'} color={'blue.100'} mb={30}/>
                <Heading as="h1" size="xl" mt={6} mb={50}>
                    Ready to dive in?
                </Heading>

                <Heading as="h1" size="xl" mt={6} mb={20}>
                    Please join our waitlist
                </Heading>
                <Text color={'gray.500'} mb={20}>
                    We are currently accepting a limited number of partners
                </Text>
            </Box>
            <Center>
                <img src={Ticket} alt="co"/>
            </Center>
        </div>
    )
}

export default Home
