import React, { Component } from 'react';
import {DashExchange} from './DashExchange';
//import Header from '../components/Header';
//import Footer from '../components/Footer';
import { ChakraProvider } from "@chakra-ui/react";

            
export default class DashboardCustomer extends Component {
  render() {
    return (
      <div className="home">
{/*          <Header></Header>*/}
          <section>
            <div>
            <ChakraProvider>
            <DashExchange />
            </ChakraProvider> 
            </div>
      </section>
{/*        <Footer></Footer>*/}
      </div>
    )
  }
}
