import {
    Box,
    chakra,
    Flex,
    Icon,
    Stack,
    Link,
    ChakraProvider,
    useColorModeValue,
  } from '@chakra-ui/react';
  import React from "react";
  
  export default function FeatureG2ridCFO() {
  const Feature = (props) => {
    return (
      <Flex>
        <Flex shrink={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            h={12}
            w={12}
            rounded="md"
            bg={useColorModeValue("brand.500")}
            color="white"
          >
            <Icon
              boxSize={6}
              fill="none"
              viewBox="0 0 24 24"
              stroke="black"
              aria-hidden="true"

            >
              {props.icon}
            </Icon>
          </Flex>
        </Flex>
        <Box ml={4}>
          <chakra.dt
            fontSize="lg"
            fontWeight="medium"
            lineHeight="6"
            color={useColorModeValue("gray.900")}
          >
            {props.title}
          </chakra.dt>
          <chakra.dd mt={2} color={useColorModeValue("gray.500", "gray.400")}>
            {props.children}
          </chakra.dd>
        </Box>
      </Flex>
    );
  };
  return (
    <><Flex
          bg={useColorModeValue("#F9FAFB", "gray.600")}
          p={20}
          w="auto"
          justifyContent="center"
          alignItems="center"
      >
          <Box py={12} bg={useColorModeValue("white", "gray.800")} rounded="xl">
              <Box maxW="7xl" mx="auto" px={{ base: 4, lg: 8 }}>
                  <Box textAlign={{ lg: "center" }}>
                      <chakra.h2
                          color={useColorModeValue("brand.600")}
                          fontWeight="semibold"
                          textTransform="uppercase"
                          letterSpacing="wide"
                      >
                          Crypto Rewards
                      </chakra.h2>
                      <chakra.p
                          mt={2}
                          fontSize={{ base: "4xl", sm: "5ffeexl" }}
                          lineHeight="8"
                          fontWeight="extrabold"
                          letterSpacing="tight"
                          color={useColorModeValue("gray.900")}
                      >
                          Start with bitcoin payments, the simplest way
                      </chakra.p>
                      <chakra.p
                          mt={4}
                          maxW="2xl"
                          fontSize="xl"
                          mx={{ lg: "auto" }}
                          color={useColorModeValue("gray.500", "gray.400")}
                      >
                          Crypto points are points whose 'value' move up and down with crypto. </chakra.p>
                          <chakra.p
                          mt={0}
                          maxW="2xl"
                          fontSize="xl"
                          mx={{ lg: "auto" }}
                          color={useColorModeValue("gray.500", "gray.400")}
                      >
                          We offer hedging solutions so that your company can offer the rewards clients crave for, attract new clients, and makes bigger profit. </chakra.p>
                  </Box>

                  <Box mt={10}>
                      <Stack
                          spacing={{ base: 10, md: 0 }}
                          display={{ md: "grid" }}
                          gridTemplateColumns={{ md: "repeat(2,1fr)" }}
                          gridColumnGap={{ md: 8 }}
                          gridRowGap={{ md: 10 }}
                      >
                          <Feature
                              title="Our solutions are cash settled only"
                              icon={<path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />}
                          >
                              We follow the process used by companies to hedge foreign exchange risk on non convertible currencies.
                              This is called non deliverable forward and $3 trillion trade every day.
                          </Feature>

                          <Feature


                              title=" Fair and simple"
                              icon={<path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />}
                          >
                              As we settle in cash, no wallet, no new IT, and no private key needed. 

                                 </Feature>

                          <Feature
                              title="$2.5+ billion of crypto rewards transactions in Q1 2022"
                              icon={<path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 10V3L4 14h7v7l9-11h-7z" />}
                          >
                              Build a looked-after experience in crypto payments. </Feature>

                          <Feature
                              title="Introduce yourself to crypto with a dedicated partner"
                              icon={<path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />}
                          >
                              Crypto rewards is the simplest way to start bitcoin. You can learn at your rythm by pacing the invited clients list.

                          </Feature>
                      </Stack>
                  </Box>
              </Box>
          </Box>
      </Flex><Flex
          bg={useColorModeValue("#F9FAFB", "gray.600")}
          p={50}
          w="full"
          alignItems="center"
          justifyContent="center"
      >
              <Flex
                  justify="center"
                  bg={useColorModeValue("white", "gray.800")}
                  w="full"
              >
                  <Box
                      w={{ base: "full", md: "75%", lg: "50%" }}
                      px={4}
                      py={20}
                      textAlign={{ base: "left", md: "center" }}
                  >
                      <chakra.span
                          fontSize={{ base: "3xl", sm: "4xl" }}
                          fontWeight="extrabold"
                          letterSpacing="tight"
                          lineHeight="shorter"
                          color={useColorModeValue("gray.900", "gray.100")}
                          mb={6}
                      >
                          <chakra.span display="block">Ready to dive in?</chakra.span>
                          <chakra.span
                              display="block"
                              color={useColorModeValue("brand.600", "gray.500")}
                          >
                              Start your free trial today.
                          </chakra.span>
                      </chakra.span>
                      <Stack
                          justifyContent={{ base: "left", md: "center" }}
                          direction={{ base: "column", sm: "row" }}
                          spacing={2}
                          mt={2}
                      >
                          <Box display="inline-flex" rounded="md" shadow="md">
                              <Link
                                  w="full"
                                  display="inline-flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  px={5}
                                  py={3}
                                  border="solid transparent"
                                  fontWeight="bold"
                                  rounded="md"
                                  color={useColorModeValue("white")}
                                  bg={useColorModeValue("#6B46C1", '#6B46C1')}
                                  _hover={{
                                      bg: useColorModeValue("#9F7AEA", '#9F7AEA'),
                                  }}
                              >
                                  Get started
                              </Link>
                          </Box>
                          <Box ml={3} display="inline-flex" rounded="md" shadow="md">
                              <Link
                                  w="full"
                                  display="inline-flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  px={5}
                                  py={3}
                                  border="solid transparent"
                                  fontWeight="bold"
                                  rounded="md"
                                  color="#2a69ac"
                                  bg="white"
                                  _hover={{
                                      bg: "brand.50",
                                  }}
                              >
                                  Learn more
                              </Link>
                          </Box>
                      </Stack>
                  </Box>
              </Flex>
          </Flex></>
  );
}
