import React, { Component } from 'react';
import {DashMonitor} from './DashMonitor';
//import Header from '../components/Header';
//import Footer from '../components/Footer';
import { ChakraProvider, Box } from "@chakra-ui/react";

            
export default class DashboardHome extends Component {
  render() {
    return (
      <div className="home">
{/*          <Header></Header>*/}
            <section>
            <div   >
            <ChakraProvider>
            <DashMonitor />
            </ChakraProvider> 
            </div>
          </section>
        {/*<Footer></Footer>*/}
      </div>
    )
  }
}
