import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Tab,
  Tabs,
  TabPanels,
  TabList,
  TabPanel,
  CircularProgress,
  CircularProgressLabel,
  Center,
  Divider,
  Heading,
  Spacer,
  Button,
  HStack,
  Container,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BsCloudDownload, BsPerson } from 'react-icons/bs';
import { AiOutlineWallet, AiOutlineSwap } from 'react-icons/ai';
import { FaBitcoin, FaEthereum, FaDollarSign } from 'react-icons/fa';
import { MdCall } from 'react-icons/md';
import PropTypes from 'prop-types'
import Feature2GridCFO from './Feature2GridCFO'
import transactionsreport from '../images/logo.png'
import VictoryFirst from './VictoryFirst'
//import VictoryAreaA from './VictoryAreaA'

//interface StatsCardProps {
//  title: string;
//  stat: string;
//  icon: ReactNode;
//}

 //  function StatsCard(props: StatsCardProps) {
export function StatsCard({ title, stat, stat_sub, icon, color }) {
//  const { title, stat, icon } = props;
  return (
    
    
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor='gray.800'
      rounded={'lg'}
      opacity={'70%'}
      bgGradient={color}>
        
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }} >
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
          <StatHelpText>
        <StatArrow type='increase' />
          {stat_sub}
        </StatHelpText>
        </Box>
        <Box
          my={'auto'}
          color='gray.800'
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  
  );
}

StatsCard.propTypes = {
    title: PropTypes.string,
    stat: PropTypes.string,
    stat_sub: PropTypes.string,
    icon: PropTypes.elementType,
    color: PropTypes.string
   }

export const StatsReport=()=> {
    //  const { title, stat, icon } = props;
      return (
//     <Flex height='80px'>
        <>
               <Flex height='80px'>
          <Box p='2'>
          <Heading size='md'>Your Portfolio Report</Heading>
          </Box>
                  <Spacer />
                  <Box>
                    <Button href={transactionsreport} leftIcon={<BsCloudDownload />} colorScheme='orange' variant='solid' mr='4'>
                      Download
                    </Button>
                    <Button rightIcon={<MdCall />} colorScheme='blue' variant='outline'>
                    Call us
                    </Button>
                  </Box>
               </Flex>
          <Table variant='striped' colorScheme='grey'>
            <TableCaption>Portfolio composition</TableCaption>
            <Thead>
              <Tr>
                <Th>Currency</Th>
                <Th>Currency Name</Th>
                <Th>Exposure</Th>
                <Th>Hedged</Th>
                <Th>Hedged / Exposure Coverage</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                <Td>Bitcoin</Td>
                <Td>$220,210,020</Td>
                <Td>$182,260,050</Td>
                <Td>
                  <CircularProgress value={80} color='green.400'>
                    <CircularProgressLabel>80%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
              <Tr>
                <Td><FaEthereum size={'3em'} color='blue' /></Td>
                <Td>Ethereum</Td>
                <Td>$310,001,010</Td>
                <Td>$242,021,110</Td>
                <Td>
                  <CircularProgress value={75} color='green.400'>
                    <CircularProgressLabel>75%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
              <Tr>
                <Td><FaDollarSign size={'3em'} color='grey' /></Td>
                <Td>Solana</Td>
                <Td>$10,102,562</Td>
                <Td>$8,212,562</Td>
                <Td>
                  <CircularProgress value={78} color='green.400'>
                    <CircularProgressLabel>78%</CircularProgressLabel>
                  </CircularProgress></Td>
              </Tr>
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Currency</Th>
                <Th>Currency Name</Th>
                <Th>Exposure</Th>
                <Th>Hedged</Th>
                <Th>Hedged / Exposure Coverage</Th>
              </Tr>
            </Tfoot>
          </Table></>
    
          
      );
    }

    export const StatsTransaction=()=> {
      //  const { title, stat, icon } = props;
        return (
          <><Flex height='80px'>
          <Box p='2'>
            <Heading size='md'>Your Transactions Report</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button href={transactionsreport} leftIcon={<BsCloudDownload />} colorScheme='orange' variant='solid' mr='4'>
              Download
            </Button>
            <Button rightIcon={<MdCall />} colorScheme='blue' variant='outline'>
            Call us
            </Button>
          </Box>
        </Flex>

 <Table variant='striped' colorScheme='grey'>
              <TableCaption>Transactions report</TableCaption>
              <Thead>
                <Tr>
                  <Th>Product Type</Th>
                  <Th>Transaction Type</Th>
                  <Th>Notional</Th>
                  <Th>Currency</Th>
                  <Th>Currency Name</Th>
                  <Th>Exec Date</Th>
                  <Th>Settlement Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Hedge</Td>
                  <Td>Increase</Td>
                  <Td>$210,012</Td>
                  <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                  <Td>Bitcoin</Td>
                  <Td>20220-06-20</Td>
                  <Td>2022-06-22</Td>
                </Tr>
                <Tr>
                  <Td>Hedge</Td>
                  <Td>Increase</Td>
                  <Td>$103,201</Td>
                  <Td><FaEthereum size={'3em'} color='blue' /></Td>
                  <Td>Ethereum</Td>
                  <Td>20220-06-19</Td>
                  <Td>2022-06-21</Td>
                </Tr>
                <Tr>
                  <Td>Borrow</Td>
                  <Td>Increase</Td>
                  <Td>$303,201</Td>
                  <Td><FaBitcoin size={'3em'} color='orange' /></Td>
                  <Td>Bitcoin</Td>
                  <Td>20220-06-18</Td>
                  <Td>2022-06-20</Td>
                </Tr>
                <Tr>
                  <Td>Hedge</Td>
                  <Td>Increase</Td>
                  <Td>$10,501</Td>
                  <Td><FaDollarSign size={'3em'} color='grey' /></Td>
                  <Td>Solana</Td>
                  <Td>20220-06-18</Td>
                  <Td>2022-06-20</Td>
                </Tr>
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>Product Type</Th>
                  <Th>Transaction Type</Th>
                  <Th>Notional</Th>
                  <Th>Currency</Th>
                  <Th>Currency Name</Th>
                  <Th>Exec Date</Th>
                  <Th>Settlement Date</Th>
                </Tr>
              </Tfoot>
            </Table></>
      
            
        );
      }


export const DashStatistics =()=> {
  return (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Feature2GridCFO/>
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        Start with bitcoin payments, the simplest way.
      </chakra.h1>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={'Portfolio balance'}
          stat={'$512,231.523'}
          stat_sub={'+ $2,231.523'}
          icon={<AiOutlineWallet size={'3em'} />}
          color="linear(to-l, #fec180,#ff8993)"
        />
        <StatsCard
          title={'Credit Line'}
          stat={'$112,503,000'}
          stat_sub={'+ $503,000'} // '\u20AC'+ ' for euro //
          icon={<AiOutlineSwap size={'3em'} />}
          color="linear(to-r, #d0ffae, #34ebe9)"
        />
        <StatsCard
          title={'Customers'}
          stat={'71,120'}
          stat_sub={'+ 320'}
          icon={<BsPerson size={'3em'} />}
          color="linear(to-r, #f3dcfb, #679fe4)"
        />
      </SimpleGrid>
      <Center height='40px'>
  <Divider orientation='vertical' />
</Center>
      <Tabs variant='soft-rounded' colorScheme='green'>
  <TabList>
    <Tab>Portfolio</Tab>
    <Tab>Transaction</Tab>
    <Tab>Analytics</Tab>
  </TabList>
  <TabPanels>
    <TabPanel>
      <p><StatsReport/></p>
    </TabPanel>
    <TabPanel>
      <p><StatsTransaction/></p>
    </TabPanel>
    <TabPanel>
      
      <HStack>
        <Container maxW='container.sm'><VictoryFirst/></Container>
        <Container maxW='container.sm'><VictoryFirst/></Container>
       {/* <Container maxW='container.sm'><VictoryAreaA/></Container> */}
      </HStack>
    </TabPanel>
  </TabPanels>
</Tabs>

  </Box>

  );
};