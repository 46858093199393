import React, { useEffect, useState } from "react";
import { VictoryChart, VictoryScatter } from "victory";
const random = (min, max) => Math.floor(min + Math.random() * max);
const getScatterData = () => {
  const colors = [
    "violet",
    "cornflowerblue",
    "gold",
    "orange",
    "turquoise",
    "tomato",
    "greenyellow"
  ];
  const symbols = [
    "circle",
    "star",
    "square",
    "triangleUp",
    "triangleDown",
    "diamond",
    "plus"
  ];
  return Array(25)
    .fill()
    .map((index) => {
      const scaledIndex = Math.floor(index % 7);
      return {
        x: random(10, 50),
        y: random(2, 100),
        size: random(8) + 3,
        symbol: symbols[scaledIndex],
        fill: colors[random(0, 6)],
        opacity: 0.6
      };
    });
};
export default function VictoryFirst() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const timer = setInterval(() => {
      setData(getScatterData());
    }, 3000);
    return () => clearInterval(timer);
  }, []);
  return (
    <VictoryChart animate={{ duration: 2000, easing: "bounce" }}>
      <VictoryScatter
        data={data}
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
            opacity: ({ datum }) => datum.opacity
          }
        }}
      />
    </VictoryChart>
  );
}